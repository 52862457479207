import React, { useEffect, useState } from 'react'
import Loading from '../../images/loading.gif'

const ProductThumbnail = ({ product }) => {
  const BASE_PRODUCT_IMAGE_URL = `${process.env.SS_CMS_BASEURL}/assets/`
  const [image, setImage] = useState(Loading)

  useEffect(() => {
    const handleFetchProductImages = async () => {
      let _image = null
      if (product.isBundle) {
        if (product.media_gallery && product.media_gallery.length > 0) {
          _image = `${product.media_gallery[0].url}`
        }
        if (!_image) {
          _image = product.thumbnail?.url
        }
      } else {
        // const sku = product.sku.replace('-', '').replace(/ +/g, '-')
        const imagesUrl = `${process.env.SS_CMS_BASEURL}/grocorpapi/getproductimages?sku=${product.order_code}`
        const response = await fetch(imagesUrl)
        const data = await response.json()
        if (response.status === 200) {
          const itemsObjects = Object.keys(data).map(key => data[key])
          if (itemsObjects.length > 0) {
            _image = `${BASE_PRODUCT_IMAGE_URL}${itemsObjects[0]}`
          }
        }
      }
      // if (!_image) {
      //   if (product.media_gallery && product.media_gallery.length > 0) {
      //     _image = `${product.media_gallery[0].url}`
      //   }
      // }
      // if (!_image) {
      //   _image = product.thumbnail?.url
      // }
      if (_image) {
        setImage(_image)
      }
    }

    handleFetchProductImages()
  }, [product.sku])

  return <img alt={product.title} src={image} />
}

export default ProductThumbnail
