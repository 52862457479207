import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { CirclePicker } from 'react-color'
import Icon from '../../../images/icons.svg'
import BigImage from '../../../images/product-big.png'
import ThumbImage1 from '../../../images/product-1.png'
import ProductModel from '../../../models/product'
import { formatPrice } from '../../../helpers/price'
import { addProductToCart } from '../../../helpers/cart'
import { setCartItems, createGuestCart } from '../../../store/cart'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import { graphql } from '../../../helpers/fetch'
import { getProductUrl } from '../../../helpers/theme'

function CustomNextArrow(props) {
  const { style, onClick } = props
  return (
    <div
      className="small-chevron-arrow next"
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

function CustomPrevArrow(props) {
  const { style, onClick } = props
  return (
    <div
      className="small-chevron-arrow prev"
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

// markup
const FeaturedProductModal = props => {
  const dispatch = useDispatch()
  const { items: priceRuleItems } = useSelector(state => state.priceRule)
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const [count, setCount] = useState(1)
  const [hex, setHex] = useState('#4F73F1')
  const [qty, setQty] = useState(1)
  const cartToken = useSelector(state => state.cart.token)
  const token = useSelector(state => state.auth.token)
  const [selectedOptions, setSelectedOptions] = useState(null)
  const [selectedColour, setSelectedColour] = useState(null)
  const [item, setItem] = useState(props.item)

  useEffect(() => {
    setItem(props.item)
  }, [props.item])

  useEffect(() => {
    if (item && item.variants && item.variants.length > 0) {
      const { product: variantObj, attributes } = item.variants[0]

      setItem(variantObj.product)

      const preselectedOptions = {}
      for (const attr of attributes) {
        preselectedOptions[attr.code] = attr.value_index
        if (attr.code == 'colour') {
          setSelectedColour(attr.label)
        }
      }
      setSelectedOptions(preselectedOptions)
    }
  }, [item])

  // useEffect(() => {
  //   if (!selectedOptions) return
  //   const selectedVariant = props.item.variants.find(({ attributes }) => {
  //     return Object.keys(selectedOptions).every(key => {
  //       const value = selectedOptions[key]
  //       return attributes.some(
  //         _attr => _attr.code === key && _attr.value_index === value
  //       )
  //     })
  //   })

  //   if (selectedVariant) {
  //     setItem(new ProductModel(selectedVariant.product, priceRuleItems))
  //   }
  // }, [selectedOptions, priceRuleItems, props.item])

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 1,
    asNavFor: nav2,
    ref: slider1 => setNav1(slider1),
  }

  const thumbSettings = {
    dots: false,
    infinite: false,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    asNavFor: nav1,
    ref: slider2 => setNav2(slider2),
    slidesToShow: 5,
    swipeToSlide: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1544,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  const handlePlus = () => {
    setQty(qty + 1)
  }

  const handleMinus = () => {
    if (qty > 0) {
      setQty(qty - 1)
    }
  }

  function handleValueChange(e) {
    e.preventDefault()
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const x = Number(e.target.value)
      setQty(x)
    }
  }

  const pricePanel = useMemo(() => {
    if (!item) return
    return (
      <div className="info-group">
        <div className="price">
          {item.isBundle ? (
            <Row>
              <Col xs={12} md={6}>
                <h4>
                  {item.isBundle && <span>From:&nbsp;</span>}
                  {formatPrice(
                    item.minPrice + (item.minPrice * 10) / 100,
                    item.currency
                  )}
                </h4>

                {item.minFullPrice > item.minPrice && (
                  <s className="old-price m-0">
                    {formatPrice(
                      item.minFullPrice +
                        (item.minFullPrice * 10) / 100,
                      item.currency
                    )}
                  </s>
                )}

                <p className="text-sm">
                  Excl. GST:{' '}{formatPrice(item.minPrice, item.currency)}
                </p>
              </Col>
              {item.maxPrice > item.minPrice && (
                <Col xs={12} md={6}>
                  <h4>
                    {item.isBundle && <span>To:&nbsp;</span>}
                    {formatPrice(
                      item.maxPrice + (item.maxPrice * 10) / 100,
                      item.currency
                    )}
                  </h4>

                  {item.maxFullPrice > item.maxPrice && (
                    <s className="old-price m-0">
                      {formatPrice(
                        item.maxFullPrice +
                          (item.maxFullPrice * 10) / 100,
                        item.currency
                      )}
                    </s>
                  )}

                  <p className="text-sm">
                    Excl. GST:{' '}{formatPrice(item.maxPrice, item.currency)}
                  </p>
                </Col>
              )}
            </Row>
          ) : (
            <>
              <h4>
                {formatPrice(
                  item.salePrice + (item.salePrice * 10) / 100,
                  item.currency
                )}
              </h4>
              <p className="text-sm">
                Excl. GST:{' '}
                {formatPrice(item.salePrice, item.currency)}
              </p>
              {item.salePrice < item.price && (
                <p className="old-price">
                  {formatPrice(
                    item.price + (item.price * 10) / 100,
                    item.currency
                  )}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    )
  }, [item])

  const addToCart = useCallback(() => {
    if (item.isBundle) {
      navigate(getProductUrl(item))
      return
    }
    addProductToCart(cartToken, item.sku, qty, token)
      .then(cart => {
        dispatch(setCartItems(cart.items))
        toast(`Added product ${item?.name} to cart`, {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
        })
      })
      .catch(e => {
        console.error(e)
        dispatch(createGuestCart())
        toast.error(
          `An error occurred while adding this product to cart. Please try again.`,
          {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
          }
        )
      })
  }, [qty, item, addProductToCart, cartToken, token, navigate, getProductUrl])

  const BASE_PRODUCT_IMAGE_URL = `${process.env.SS_CMS_BASEURL}/assets/`
  const [productImages, setProductImages] = useState([])

  useEffect(() => {
    if (!item) return
    const handleFetchProductImages = async () => {
      if (item.isBundle) {
        if (item.media_gallery && item.media_gallery.length > 0) {
          setProductImages(item.media_gallery)
        } else {
          setProductImages([])
        }
      } else {
        // let sku = item.sku.replace('-', '')
        // sku = sku.replace(/ +/g, '-')
        let imagesUrl = `${process.env.SS_CMS_BASEURL}/grocorpapi/getproductimages?sku=${props.item.order_code}`
        const response = await fetch(imagesUrl)
        const data = await response.json()
        if (response.status === 200) {
          const itemsObjects = Object.keys(data).map(key => data[key])
          const _productImages = []
          for (let i = 0; i < itemsObjects.length; i++) {
            let _image = { url: `${BASE_PRODUCT_IMAGE_URL}${itemsObjects[i]}` }
            _productImages.push(_image)
          }
          setProductImages(_productImages)
        }
      }
    }

    handleFetchProductImages()
  }, [item])

  useEffect(() => {
    if (!selectedOptions) return
    const selectedVariant = props.item.variants.find(({ attributes }) => {
      return Object.keys(selectedOptions).every(key => {
        const value = selectedOptions[key]
        return attributes.some(
          _attr => _attr.code === key && _attr.value_index === value
        )
      })
    })

    if (selectedVariant) {
      setItem(new ProductModel(selectedVariant.product, priceRuleItems))
    }
  }, [selectedOptions, priceRuleItems, props.item])

  return (
    <Modal
      {...props}
      size="lg"
      centered
      backdropClassName="quickview-backdrop"
      className="quickview-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {!!props.item && (
          <Row>
            {props.item.variants.length > 0 ? (
              <Col xs={12} lg={6}>
                <Slider {...settings} className="product-image-slider">
                  {props.item.variants.map(({ product: { image } }, index) => (
                    <div
                      className="product-image-item"
                      key={`main-${index}-${image.url}`}
                    >
                      <img src={image.url} />
                      <div className="btn-zoom">
                        <svg width="20" height="20">
                          <use xlinkHref={`${Icon}#icon-search`}></use>
                        </svg>
                      </div>
                    </div>
                  ))}
                </Slider>
                <Slider {...thumbSettings} className="product-thumb-slider">
                  {props.item.variants.map(({ product: { image } }, index) => (
                    <div
                      className="product-thumb-item"
                      key={`thumb-${index}-${image.url}`}
                    >
                      <img src={image.url} />
                    </div>
                  ))}
                </Slider>
              </Col>
            ) : (
              <Col xs={12} lg={6}>
                {productImages.length > 0 && (
                  <Slider {...settings} className="product-image-slider">
                    {productImages.map((image, index) => (
                      <div
                        className="product-image-item"
                        key={`main-${index}-${image.url}`}
                      >
                        <img src={image.url} />
                        <div className="btn-zoom">
                          <svg width="20" height="20">
                            <use xlinkHref={`${Icon}#icon-search`}></use>
                          </svg>
                        </div>
                      </div>
                    ))}
                  </Slider>
                )}
                {productImages.length > 0 && (
                  <Slider {...thumbSettings} className="product-thumb-slider">
                    {productImages.map((image, index) => (
                      <div
                        className="product-thumb-item"
                        key={`thumb-${index}-${image.url}`}
                      >
                        <img src={image.url} />
                      </div>
                    ))}
                  </Slider>
                )}
              </Col>
            )}
            <Col xs={12} lg={6}>
              <div className="product-info mt-0">
                <div className="info-top mb-3">
                  <h1 className="h3">{props.item.name}</h1>
                  {!!item?.order_code && <p className="text-sm text-secondary">
                    CODE: {item.order_code}
                  </p>}
                </div>
                <div className="info-group">{pricePanel}</div>
                {!!selectedOptions &&
                  props.item.configurable_options.map(configOption => (
                    <div
                      className="info-group"
                      key={`${configOption.attribute_code}-v-group`}
                    >
                      {configOption.attribute_code === 'colour' ? (
                        <div className="d-flex align-item-start justify-content-between">
                          <div className="d-flex flex-column flex-lg-row">
                            <h5>{configOption.label}</h5>
                            <p className="text-sm text-secondary ms-0 ms-lg-2">
                              {selectedColour}
                            </p>
                          </div>
                          <div>
                            <CirclePicker
                              colors={configOption.values
                                .filter(
                                  optionVal =>
                                    optionVal.value_index !== null &&
                                    !!optionVal.swatch_data?.value
                                )
                                .map(optionVal => optionVal.swatch_data?.value)}
                              color={
                                selectedOptions
                                  ? configOption.values.find(
                                      _val =>
                                        _val.value_index ===
                                        selectedOptions[
                                          configOption.attribute_code
                                        ]
                                    ).swatch_data?.value
                                  : null
                              }
                              onChange={color => {
                                setSelectedOptions(_options => {
                                  const findOption = configOption.values.find(
                                    _val => {
                                      if (
                                        _val.swatch_data &&
                                        _val.swatch_data.value
                                      ) {
                                        return (
                                          _val.swatch_data.value.toLowerCase() ===
                                          color.hex.toLowerCase()
                                        )
                                      }
                                      return false
                                    }
                                  )
                                  setSelectedColour(findOption.label)
                                  if (findOption) {
                                    _options[configOption.attribute_code] =
                                      findOption.value_index
                                  }
                                  return { ..._options }
                                })
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="size-group">
                            {configOption.values
                              .filter(
                                optionVal => optionVal.value_index !== null
                              )
                              .map((optionVal, index) => (
                                <div
                                  className="radio-group"
                                  key={`${optionVal.value_index}-${configOption.attribute_code}`}
                                >
                                  <input
                                    type="radio"
                                    id={`option-${configOption.attribute_code}-${optionVal.value_index}`}
                                    value={optionVal.value_index}
                                    checked={
                                      selectedOptions
                                        ? optionVal.value_index ===
                                          selectedOptions[
                                            configOption.attribute_code
                                          ]
                                        : false
                                    }
                                    onChange={() => {
                                      setSelectedOptions(_options => {
                                        _options[configOption.attribute_code] =
                                          optionVal.value_index
                                        return { ..._options }
                                      })
                                    }}
                                  />
                                  <label
                                    htmlFor={`option-${configOption.attribute_code}-${optionVal.value_index}`}
                                  >
                                    {optionVal.label}
                                  </label>
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                <div className="info-group pb-0">
                  <h5>QUANTITY</h5>
                  <div className="quantity-group mt-3 mb-4">
                    <button onClick={handleMinus}>-</button>
                    <input
                      type="number"
                      min="0"
                      value={qty}
                      onChange={handleValueChange}
                    />
                    <button onClick={handlePlus}>+</button>
                  </div>
                  <div className="btn-wrap">
                    <a onClick={addToCart} className="btn btn-primary">
                      Add to Cart
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default FeaturedProductModal
