import { graphql } from './fetch'

export const addProductToCart = async (cartToken, sku, qty, authToken = null) => {
  const { data: res, errors } = await graphql(
    `${process.env.GATSBY_CMS_URL}/graphql`,
    `
    mutation {
      addSimpleProductsToCart(
        input: {
          cart_id: "${cartToken}"
          cart_items: [{ data: { quantity: ${qty}, sku: "${sku}" } }]
        }
      ) {
        cart {
          items {
            id
            product {
              sku
              stock_status
            }
            quantity
          }
        }
      }
    }
  `,
      authToken
  )

  if (errors) {
    throw new Error('Oops! An error occurred while adding product to cart')
  }

  return res.addSimpleProductsToCart.cart
}
