module.exports.getBlockContent = (blocks, key) => {
  const item = blocks.find(b => b && b.identifier === key)
  return item ? item.content : null
}

module.exports.getProductAttributeOptions = (attributes, key) => {
  const item = attributes.find(b => b && b.attribute_code === key)
  return item ? item.attribute_options : []
}

module.exports.getProductUrl = product => {
  if (!product) return null

  // if (product.url_rewrites && product.url_rewrites.length > 0) {
  //   return `/${product.url_rewrites[product.url_rewrites.length - 1].url}`
  // }

  return `/${product.url_key}`
}

module.exports.buildProductBreadcrumb = product => {
  if (!product.categories) return []

  const categories = product.categories.sort((a, b) => {
    const levelDiff = a.level - b.level
    if (levelDiff !== 0) return levelDiff

    return a.position - b.position
  })
  
  const items = []  
  if (typeof window !== 'undefined') {
    let previousCategoryKey
    const segments = window.location.pathname.split('/').filter(p => p)

    for (const category of categories) {
      if (segments.length <= 1) {
        if (items.length === 0 || category.url_path.startsWith(previousCategoryKey)) {
          items.push(category)
          previousCategoryKey = category.url_path
        }
      } else {
        if (window.location.pathname.startsWith(`/${category.url_path}`)) {
          items.push(category)
        }
      }
    }
  }

  return items
}

module.exports.buildProductName = productName => {
  const nameArray = productName.split(' in ')
  const name = nameArray[0].trim()
  const subName = productName.replace(name, '').trim()

  return [name, subName]
}

module.exports.getProductUrlByCategory = (product, catId) => {
  if (!product) return null
  // var cat_url
  // if (product.url_rewrites && product.url_rewrites.length > 0) {
  //   cat_url = `/${product.url_rewrites[product.url_rewrites.length - 1].url.replace(/^\/|\/$/g, '')}`
  //   product.url_rewrites.map(function (url) {
  //     if(url.parameters.length > 1){
  //       url.parameters.map(function (param) {
  //         if(param.name === 'category' && parseInt(param.value) === parseInt(catId)){
  //           cat_url = `/${url.url.replace(/^\/|\/$/g, '')}`
  //         }
  //       })
  //     }

  //   })
  //   return cat_url
  // }
  return `/${product.url_key}`
}

module.exports.isJsonString = str => {
  try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}